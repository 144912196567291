import { NgModule, ViewChild, HostListener, ViewChildren, QueryList } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, Renderer2 } from '@angular/core';
import { IonicModule, NavController, IonModal } from '@ionic/angular';
import { RestProvider } from '../providers/rest/rest';
import { TranslateService } from '@ngx-translate/core';
import { SpeechRecognitionService } from './speechrecognition.service';
import { FormControl, FormsModule } from '@angular/forms';
import { saveAs } from 'file-saver';
import { ENV } from '../../environments/environment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { OverlayEventDetail } from '@ionic/core/components';
import { ToastController } from '@ionic/angular';
import { IonDatetime } from '@ionic/angular';
import domtoimage from 'dom-to-image';

@Component({
  selector: 'page-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {

  readonly CHATBOT_NAME = 'ADAM';
  readonly USER_LABEL = 'Me';
  readonly LOADING_BAR = '<div class="circle-container">' +
    '<div class="circleG_1 circleG"></div>' +
    '<div class="circleG_2 circleG"></div>' +
    '<div class="circleG_3 circleG"></div>' +
    '</div>';
  readonly ENTRY_INTENT = '263499716357161634';
  readonly NO_OF_TAC_MAX_FAILED_ATTEMPT = 3;
  readonly AUTH_FAILED_ERROR_MSG_MAX_ATTEMPT = 'Exceed maximum failed attempt. Please retry later.';
  readonly AUTH_FAILED_ERROR_MSG_ACCESS_DENIED = 'Authentication failed. Access to chatbot denied.';
  readonly AUTH_FAILED_ERROR_MSG_INVALID_ID = 'Authentication failed. Please try again.';
  readonly LOGIN_MSG_STEP_1 = 'Please enter staff ID';
  readonly LOGIN_MSG_STEP_2 = 'Please enter IC Number';
  readonly LOGIN_MSG_STEP_3 = '6 Digit TAC have been sent to your mobile phone. Please key in the TAC number that you have received';
  readonly LOGIN_MSG_STEP_4 = 'Login successful';
  readonly ERROR_KEY_TAC_FAILED = 'tacError';
  readonly LANGUAGE_FIELD = 'LANGUAGE';
  readonly BAHASA_MALAYSIA = 'Bahasa Malaysia';
  readonly FILE_EXTENSION_PATTERN = /\.[0-9a-z]+$/i;
  readonly ACCEPTED_FILE_TYPE = 'jpeg, .jpg, .png, .bmp, .tif, .tiff, .heic, .pdf, .doc, .docx, .xls, .xlsx';
  users: any;
  message = { text: '', id: '', field: '', fileBase64: '' };

  messages: any;

  lang: any;
  id: any;
  defaultPlaceholder = 'Type message here';
  currentPlaceholder = '';
  accessToken = ENV.ACCESS_TOKEN;
  focusingBool = true;
  voiceOn = false;
  queryField: FormControl = new FormControl();
  queryResults: any;
  authState: string = null;
  authFailedAttempt: number = 0;
  staffId: string = null;
  idNumber: string = null;
  tac: string = null;
  preAuth: string = null;
  avatar: string = '';
  selectedFile: File | null = null;

  public translatedLabel;
  public translatedHolder;

  sigPadElement: any;
  context: any;
  isDrawing: boolean;
  isQueryFieldDisabled: boolean = false;

  sessionTimer: ReturnType<typeof setTimeout> | null = null;
  sessionTimeout: number;
  notiBeforeSessionTimedout = 60000; // 1 min
  isSessionEnded: boolean = false;

  isDateConfirmButtonDisabled: boolean = true;
  selectedDate: string;

  tableImage: string | null = null;
  showTableImage: boolean = false;

  @ViewChild('content') private content: any;
  @ViewChild('signModal') modal: IonModal;
  @ViewChildren('signPad') signPad: QueryList<ElementRef<HTMLCanvasElement>>;
  @ViewChild('datetime', { static: false }) datetime: IonDatetime;
  @ViewChildren('messageSpanText') messageSpanTextList!: QueryList<ElementRef>;

  constructor(public navCtrl: NavController, public restProvider: RestProvider, public translate: TranslateService, private speechRecognitionService: SpeechRecognitionService,
    private elementRef: ElementRef, private renderer: Renderer2, public toastController: ToastController) {
    this.lang = this.translate.getDefaultLang();
    this.message.text = this.ENTRY_INTENT;
    this.translate.get('PLC_HOLDER.ENTER_USERNAME').subscribe(translated => { this.translatedHolder = translated; });
    this.currentPlaceholder = this.defaultPlaceholder;
    this.enableQueryField();
    this.isSessionEnded = false;

    this.restProvider.getExpiry(this.accessToken).then((result) => {
      if (result) {
        this.sessionTimeout = Number(result) * 60000;
      }
    })

    this.restProvider.getAvatarImage(this.accessToken).then((result) => {
      if (result) {
        this.avatar = 'data:' + result["avatarContentType"] + ';base64,' + result["avatarImage"];
        const favicon: HTMLLinkElement = document.querySelector('#favicon');
        if (favicon) {
          favicon.href = 'data:' + result["avatarContentType"] + ';base64,' + result["avatarImage"];
        }
      }
    })

    this.restProvider.askRobot(this.message, this.accessToken, this.lang).then((result) => {
      this.setContextId(result["id"]);
      this.message.field = result["field"];
      this.message.text = '';
      if (result["accessToken"] != null)
        this.accessToken = 'Bearer ' + result["accessToken"];

      var reformatHtmlTagMsg = result["text"];
      for (var i = 0; i < result["text"].length; i++) {
        reformatHtmlTagMsg = reformatHtmlTagMsg.replace(/[\n+]/g, '<br />');
      }
      this.messages = [{
        "image": result["image"],
        "name": reformatHtmlTagMsg,
        "who": this.CHATBOT_NAME,
        "option": result["option"],
      }];

      this.setSessionTimer();

    }, (err) => {
      if (err.status === 401) {
        // enter login flow
        this.authState = 'loginStep1';
        this.message.text = '';
        this.messages = [{ name: this.LOGIN_MSG_STEP_1, who: this.CHATBOT_NAME }];
      } else {
        this.messages = [{ name: err.message, who: this.CHATBOT_NAME }];
      }
    });

  }

  async notifyUser(notification, duration) {
    const toast = await this.toastController.create({
      message: notification,
      duration: duration,
      color: "light",
      position: "middle",
      mode: "ios",
      buttons: [
        {
          text: 'Dismiss',
          role: 'cancel',
        }
      ],
    });
    toast.present();

    const { role } = await toast.onWillDismiss();
  }

  setSessionTimer = () => {
    this.sessionTimer = setTimeout(() => {
      this.translate.get('NOTIFICATIONS.SESSION_ENDS').subscribe(translated => { this.translatedHolder = translated; });
      this.notifyUser(this.translatedHolder, 5000);
    }, this.sessionTimeout - this.notiBeforeSessionTimedout);

    this.sessionTimer = setTimeout(() => {
      this.disableQueryField();
      this.translate.get('NOTIFICATIONS.SESSION_ENDED').subscribe(translated => { this.translatedHolder = translated; });
      this.notifyUser(this.translatedHolder, 5000);
      this.translate.get('NOTIFICATIONS.SESSION_ENDED_CHATBOT').subscribe(translated => { this.translatedHolder = translated; });
      this.messages.push({ name: this.translatedHolder, who: this.CHATBOT_NAME });
      this.isSessionEnded = true;
    }, this.sessionTimeout);

  }

  onDateChange(datetime: IonDatetime) {
    datetime.confirm().then(() => {
      const selectedDate = datetime.value;
      this.isDateConfirmButtonDisabled = !selectedDate;
    }).catch(error => {
      console.error('Error confirming Date/Time', error);
    });
  }

  confirmDateTime(datetime: IonDatetime) {
    datetime.confirm().then(() => {
      const selectedDate = datetime.value;
      this.messages.push({ name: selectedDate.slice(0, 10), who: this.USER_LABEL });
      this.callToRobot(selectedDate.slice(0, 10));
      datetime.disabled = true;
      this.isDateConfirmButtonDisabled = true;
    }).catch(error => {
      console.error('Error confirming Date/Time', error);
    });
  }

  cancel() {
    this.modal.dismiss(null, 'cancel');
  }

  confirm() {
    this.modal.dismiss(this.sigPadElement.toDataURL('image/png'), 'confirm');
    this.messages[this.messages.length - 1].isSelectionDisable = true;
  }

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
    if (ev.detail.role === 'confirm') {
      const signatureImg = this.sigPadElement.toDataURL('image/png');
      this.message.id = this.getContextId();
      this.message.text = "signed";
      this.message.fileBase64 = signatureImg;
      this.restProvider.askRobot(this.message, this.accessToken, this.lang).then((result) => {
        this.standardAskRobot(result);
      }, (err) => {
        this.messages.push({ name: err.message, who: this.CHATBOT_NAME });
      }).then(() => {
        setTimeout(() => {
          this.setInputFocusAndScrollHeight();
        }, 300);
      });
      this.message.text = '';
      this.message.fileBase64 = '';
    }
  }

  ngOnInit_disableSuggestion() {
    this.queryField.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe(result => {
        if (result == "") {
          this.queryResults = null;
          return;
        }
        this.message.text = result;
        this.restProvider.askSuggestion(this.message, this.accessToken, this.lang).then((chatbotResult) => {
          this.queryResults = chatbotResult;
        }, (err) => {
          this.messages.push({ name: err.message, who: this.CHATBOT_NAME });
        }).then(() => {
          setTimeout(() => {
            this.setInputFocusAndScrollHeight();
          }, 300);
        });
      });
  }

  ngAfterViewInit() {
    this.signPad.changes.subscribe(() => {
      const firstSignPad = this.signPad.first?.nativeElement;
      if (firstSignPad) {
        this.sigPadElement = firstSignPad;
        this.context = this.sigPadElement.getContext('2d');
        this.context.strokeStyle = '#000';
      }
    });
    setTimeout(() => this.renderer.selectRootElement('#msgInput').focus());

    this.messageSpanTextList.changes.subscribe((messageSpanTexts: QueryList<ElementRef>) => {
      messageSpanTexts.forEach((messageSpanText) => {
        var index = messageSpanText.nativeElement.id.split('-').pop();
        let i = 1;
        let table;

        do {
          const tableId = `table-container-${index}-${i}`;
          table = document.getElementById(tableId);

          if (table) {
            const currentTable = table;
            this.renderer.listen(currentTable, 'click', () => {
              this.captureTable(currentTable);
            });
            i++;
          }
        } while (table != null);

      });
    });
  }

  captureTable(table: any) {
    domtoimage.toPng(table)
      .then((dataUrl) => {
        this.tableImage = dataUrl;
        this.showTableImage = true;
        const tableDiv = document.getElementById('table-div');
        if (tableDiv) {
          this.renderer.listen('document', 'click', () => {
            this.showTableImage = false;
          });
        }
      })
      .catch((error) => {
        console.error('Error capturing table as image:', error);
      });
  }

  closeTableImageModal() {
    this.showTableImage = false;
  }

  switchLanguage(evt) {
    this.translate.setDefaultLang(evt);
    this.translate.use(evt);
    this.reload();
  }

  messageInput() {
    if (!this.isQueryFieldDisabled) {
      if (this.message.text != undefined && this.message.text.trim().length <= 0) {
        // don't process input if empty
        return;
      }
      this.message.id = this.getContextId();
      if (!this.message.text) {
        this.translate.get('DEFAULT_MSG').subscribe(translated => { this.translatedHolder = translated; });
        this.messages.push({ name: this.message.text, who: this.USER_LABEL });
        this.messages.push({ name: this.translatedHolder, who: this.CHATBOT_NAME });
        setTimeout(() => {
          this.setInputFocusAndScrollHeight();
        }, 300);
        return;
      }

      if (this.message.field == 'password') {
        this.messages.push({ name: this.message.text.replace(/./gi, "*"), who: this.USER_LABEL });
      } else {
        this.messages.push({ name: this.message.text, who: this.USER_LABEL });
      }
      setTimeout(() => {
        this.setInputFocusAndScrollHeight();
      }, 300);

      this.messages.push({ id: 'loading', name: this.LOADING_BAR, who: this.CHATBOT_NAME });
      let loadingIdx = this.messages.findIndex(x => x.id == 'loading');

      if (!this.processLoginSteps()) {
        // proceed to general intent flow
        this.message.text = this.message.text.trim();
        this.restProvider.askRobot(this.message, this.accessToken, this.lang).then((result) => {
          this.messages.splice(loadingIdx, 1);
          this.standardAskRobot(result);
        }, (err) => {
          this.messages.splice(loadingIdx, 1);
          this.messages.push({ name: err.message, who: this.CHATBOT_NAME });
        }).then(() => {
          setTimeout(() => {
            this.setInputFocusAndScrollHeight();
          }, 300);
        });
      }

      this.message.text = '';
    }
  }

  startVoice() {
    this.voiceOn = true;
    this.speechRecognitionService.record()
      .subscribe(
        (value) => {
          console.log('speechRecognitionService record');
          console.log(value);
          this.message.text = this.message.text + ' ' + value;
          this.startVoice();
        });
  }

  stopVoice() {
    this.voiceOn = false;
    this.speechRecognitionService.DestroySpeechObject();
  }

  setContextId(contextId) {
    this.id = contextId;
    if (typeof (Storage) !== "undefined") {
      // Code for localStorage/sessionStorage.
      sessionStorage.contextId = contextId;
    } else {
      // Sorry! No Web Storage support..
    }
  }

  getContextId() {
    if (typeof (Storage) !== "undefined") {
      // Code for localStorage/sessionStorage.
      return sessionStorage.contextId;
    } else {
      return this.id;
    }
  }

  textSelected(optionLength, selectedValue, selectedDetailLabel) {
    var disabled = false;
    this.messages[this.messages.length - 1].isSelectionDisable = true;
    this.message.id = this.getContextId();
    if (optionLength > 0 && disabled === false) {
      if (this.message.field == this.LANGUAGE_FIELD) {
        if (selectedDetailLabel == this.BAHASA_MALAYSIA) {
          this.translate.use('ms');
          this.lang = this.translate.currentLang;
        }
      }
      this.message.text = selectedValue;
      this.messages.push({ name: selectedDetailLabel, who: this.USER_LABEL });

      this.restProvider.askRobot(this.message, this.accessToken, this.lang).then((result) => {
        this.standardAskRobot(result);
      }, (err) => {
        this.messages.push({ name: err.message, who: this.CHATBOT_NAME });
      }).then(() => {
        setTimeout(() => {
          this.setInputFocusAndScrollHeight();
        }, 300);
      });
      this.message.text = '';
      this.queryResults = null;
    } else {

    }
  }

  imageSelected(selectedValue, messageid) {
    var disabled = false;
    this.messages[this.messages.length - 1].isSelectionDisable = true;
    this.message.id = this.getContextId();
    if (selectedValue != undefined && disabled === false) {
      this.message.text = selectedValue;
      var proceedBtnClass = '.cardProceed' + messageid;
      var proceedButton = document.querySelectorAll(proceedBtnClass);
      if (proceedButton.length > 0) {
        var proceedBtnText = proceedButton[proceedButton.length - 1].innerHTML;
        proceedBtnText = proceedBtnText.substr(proceedBtnText.indexOf("Proceed"), proceedBtnText.length);
        proceedBtnText = proceedBtnText.substr(0, proceedBtnText.indexOf("<"));
        this.messages.push({ name: proceedBtnText, who: this.USER_LABEL });
      }

      this.restProvider.askRobot(this.message, this.accessToken, this.lang).then((result) => {
        this.standardAskRobot(result);
      }, (err) => {
        this.messages.push({ name: err.message, who: this.CHATBOT_NAME });
      }).then(() => {
        setTimeout(() => {
          this.setInputFocusAndScrollHeight();
        }, 300);
      });
      this.message.text = '';
    } else {

    }
  }

  handleFileChange(event: any, message: any) {
    const fileInput = event.target;
    const files = fileInput.files;
    const maxFileSize = 15 * 1024 * 1024; //15MB

    if (files[0].size >= maxFileSize) {
      this.translate.get('NOTIFICATIONS.FILE_EXCEED_LIMIT').subscribe(translated => { this.translatedHolder = translated; });
      this.notifyUser(this.translatedHolder, 0);
      fileInput.value = '';
    } else if (!this.FILE_EXTENSION_PATTERN.test(files[0].name)) {
      this.translate.get('NOTIFICATIONS.INVALID_FILE_EXTENSION').subscribe(translated => { this.translatedHolder = translated; });
      this.notifyUser(this.translatedHolder, 0);
      fileInput.value = '';
    } else {
      message.file = files.length > 0 ? files[0] : null;
    }
  }

  getFileName(file: File) {
    this.setInputFocusAndScrollHeight();
    return file ? file.name : '';
  }

  skipUploadingFile(skip) {
    this.messages[this.messages.length - 1].isSelectionDisable = true;
    this.messages.push({ name: skip, who: this.USER_LABEL });
    this.callToRobot(skip);
  }

  uploadFile(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      let base64 = event.target.result as string;

      const mimeType = base64.substring(5, base64.indexOf(';'));
      const base64Prefix = `data:${mimeType};base64,`;

      if (base64.startsWith(base64Prefix)) {
        base64 = base64.substring(base64Prefix.length);
      }
      this.messages[this.messages.length - 1].isSelectionDisable = true;
      this.message.id = this.getContextId();
      this.message.text = file.name.split('.').pop();
      this.message.fileBase64 = base64;
      this.restProvider.askRobot(this.message, this.accessToken, this.lang).then((result) => {
        this.standardAskRobot(result);
      }, (err) => {
        this.messages.push({ name: err.message, who: this.CHATBOT_NAME });
      }).then(() => {
        setTimeout(() => {
          this.setInputFocusAndScrollHeight();
        }, 300);
      });
      this.message.text = '';
      this.message.fileBase64 = '';
    };

    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

  deselectOthers(messageid, indexSelected, message) {
    var className = '.selimg' + messageid;
    var els = document.querySelectorAll(className);

    var cardChecked = false;
    for (var i = 0; i < els.length; i++) {
      var ele = <HTMLInputElement>els[i];
      if (i == indexSelected && ele.checked == true) {
        message.selectedValue = message.option[i].detailValue.id;
        cardChecked = true;
      } else if (ele.checked == true) {
        ele.checked = false;
      }
    }
    if (cardChecked === false) {
      message.selectedValue = undefined;
    }

    this.translate.get('LABELS.CC_PROCEEDS').subscribe(translated => { this.translatedLabel = translated; });
    var proceedBtnClass = '.cardProceed' + messageid;
    var proceedButton = document.querySelectorAll(proceedBtnClass);
    if (proceedButton.length > 0 && message.selectedValue != undefined) {
      var htmlContent = proceedButton[proceedButton.length - 1].innerHTML;
      let start = htmlContent.indexOf(">") + 1;
      let end = htmlContent.indexOf("</span>");
      let newStr = this.translatedLabel + message.selectedValue;
      proceedButton[proceedButton.length - 1].innerHTML = htmlContent.replace(htmlContent.substring(start, end), newStr);
    } else if (proceedButton.length > 0 && message.selectedValue == undefined) {
      proceedButton[proceedButton.length - 1].innerHTML = proceedButton[proceedButton.length - 1].innerHTML
        .replace(/Proceed.*</, 'Proceed with card ending with <');
    }
  }

  generateRandomNumberMsgId() {
    return Math.floor((Math.random() * 100000) + 1);
  }

  disableQueryField() {
    this.queryField.disable();
    this.isQueryFieldDisabled = true;
  }

  enableQueryField() {
    if (!this.isSessionEnded) {
      this.queryField.enable();
      this.isQueryFieldDisabled = false;
    }
  }


  standardAskRobot(result) {
    if (!this.isSessionEnded) {
      this.setContextId(result["id"]);
      this.message.field = result["field"];
      this.enableQueryField();

      if (result["accessToken"] != null)
        this.accessToken = 'Bearer ' + result["accessToken"];


      // NOTE: reset msgInput input type to text
      var msgInput = <HTMLInputElement>document.getElementById('msgInput');
      msgInput.type = "text";

      // if (result["text"].indexOf('username') > -1) {
      //   this.translate.get('PLC_HOLDER.ENTER_USERNAME').subscribe(translated => { this.translatedHolder = translated; });
      //   this.currentPlaceholder = this.translatedHolder;
      // } else if (result["text"].indexOf('password') > -1 || result["text"].indexOf('kekunci') > -1) {
      //   this.translate.get('PLC_HOLDER.ENTER_PASSWORD').subscribe(translated => { this.translatedHolder = translated; });
      //   this.currentPlaceholder = this.translatedHolder;
      //   msgInput.type = "password";
      // } else {
      this.currentPlaceholder = this.defaultPlaceholder;
      // }

      var reformatHtmlTagMsg = result["text"];
      reformatHtmlTagMsg = reformatHtmlTagMsg.replace(/[\n+]/g, '<br />');

      reformatHtmlTagMsg = reformatHtmlTagMsg.replace(/{{x\.messageid}}/g, `${this.messages.length}`);;

      reformatHtmlTagMsg = reformatHtmlTagMsg.split('<br />')
        .filter(line => !line.includes('%('))
        .join('<br />');;

      this.translate.get('SKIP').subscribe((translatedSkip: string) => {
        if (result.hasOwnProperty("image") && result["image"] != null) {
          this.messages.push({
            name: reformatHtmlTagMsg,
            who: this.CHATBOT_NAME,
            option: result["option"],
            image: result["image"],
            medias: result["medias"],
            messageid: this.generateRandomNumberMsgId()
          });
        } else if (!["SKIP", translatedSkip].includes(reformatHtmlTagMsg)) {
          this.messages.push({
            name: reformatHtmlTagMsg,
            who: this.CHATBOT_NAME,
            option: result["option"],
            medias: result["medias"],
            messageid: this.generateRandomNumberMsgId()
          });
        }



        if (result["option"].length > 0) {
          this.disableQueryField();
        }

        if (result['disableInput']) {
          this.disableQueryField();
          this.isSessionEnded = true;
        }

        if (result["goToIntent"] != null) {
          if (result["goToIntent"] == '/goto resend_tac') {
            this.disableQueryField();
            setTimeout(() => {
              this.callToRobot(result["goToIntent"]);
            }, 15000);
          } else if (["SKIP", translatedSkip].includes(reformatHtmlTagMsg) || result["goToIntent"] == '/goto captcha') {
            this.callToRobot(result["goToIntent"]);
          }
          else {
            setTimeout(() => {
              this.callToRobot(result["goToIntent"]);
            }, 2000);
          }
        }

        if (result["fileBase64"] != null) {
          var blob = new Blob([result["fileBase64"]], { type: "application/pdf" });
          saveAs(blob, "Application Form.pdf");
        }
      });
    }
  }

  logout() {
    this.speechRecognitionService.DestroySpeechObject();
    this.message.text = 'logout';
    this.messageInput();
    this.id = undefined;
    sessionStorage.removeItem('contextId');
    this.accessToken = '';
  }

  setInputFocusAndScrollHeight() {
    this.content.scrollToBottom(300);
  }

  reload() {
    this.message.text = 'login';
    this.translate.get('PLC_HOLDER.ENTER_USERNAME').subscribe(translated => { this.translatedHolder = translated; });
    this.currentPlaceholder = this.translatedHolder;

    this.restProvider.askRobot(this.message, this.accessToken, this.lang).then((result) => {
      this.setContextId(result["id"]);
      this.message.field = result["field"];
      this.message.text = '';
      if (result["accessToken"] != null)
        this.accessToken = 'Bearer ' + result["accessToken"];

      this.messages = [{
        "name": result["text"],
        "who": this.CHATBOT_NAME
      }];

    }, (err) => {
      this.messages.push({ name: err.message, who: this.CHATBOT_NAME });
    }).then(() => {
      setTimeout(() => {
        this.setInputFocusAndScrollHeight();
      });
    });
  }

  callToRobot(data: any) {
    this.message.id = this.getContextId();
    this.message.text = data;
    this.restProvider.askRobot(this.message, this.accessToken, this.lang).then((result) => {
      this.standardAskRobot(result);
    }, (err) => {
      this.messages.push({ name: err.message, who: this.CHATBOT_NAME });
    }).then(() => {
      setTimeout(() => {
        this.setInputFocusAndScrollHeight();
      }, 300);
    });
    this.message.text = '';
  }

  downloadFile() {
    let fileContent = '';
    for (let entry of this.messages) {
      fileContent = fileContent + '\r\n' + entry.who + ':' + this.stripHtmlTag(entry.name);
    }

    var blob = new Blob([fileContent], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "ChatHistory.txt");
  }

  stripHtmlTag(html: string) {
    var formatHtmtTag = html.replace(/<br[^>]*>/gi, '\n');
    var tmpDivEle = document.createElement("div");
    tmpDivEle.innerHTML = formatHtmtTag;
    return tmpDivEle.textContent || tmpDivEle.innerText || "";
  }

  getAuthErrorMessage(err: any) {
    if (err.error && err.error.title) {
      return err.error.title;
    } else {
      return err.message;
    }
  }

  processLoginSteps() {
    let loadingIdx = this.messages.findIndex(x => x.id == 'loading');
    if (this.authState === 'loginStep1') {
      // entered staff id, keep for next step
      this.staffId = this.message.text;
      // prompt id number
      this.messages.splice(loadingIdx, 1);
      this.messages.push({ name: this.LOGIN_MSG_STEP_2, who: this.CHATBOT_NAME });
      this.authState = 'loginStep2';
      return true;
    } else if (this.authState === 'loginStep2') {
      // entered id number
      this.idNumber = this.message.text;
      const preLoginReq = {
        id: this.getContextId(),
        staffId: this.staffId,
        idNumber: this.idNumber
      };
      // call prelogin api
      this.restProvider.preLogin(preLoginReq, this.lang).then((result) => {
        // prompt successful login
        this.messages.splice(loadingIdx, 1);
        this.messages.push({ name: this.LOGIN_MSG_STEP_4, who: this.CHATBOT_NAME });
        this.authState = null;
        this.accessToken = 'Bearer ' + result["id_token"];
        this.callToRobot(this.ENTRY_INTENT);
      }, (err) => {
        this.messages.splice(loadingIdx, 1);
        if (err.error.errorKey && err.error.errorKey === this.ERROR_KEY_TAC_FAILED) {
          this.authFailedAttempt++;
          if (this.authFailedAttempt >= this.NO_OF_TAC_MAX_FAILED_ATTEMPT) {
            this.messages.push({ name: this.AUTH_FAILED_ERROR_MSG_MAX_ATTEMPT, who: this.CHATBOT_NAME });
            this.messages.push({ name: this.AUTH_FAILED_ERROR_MSG_ACCESS_DENIED, who: this.CHATBOT_NAME });
            this.queryField.disable();
            return true;
          }
        }
        this.messages.push({ name: this.getAuthErrorMessage(err), who: this.CHATBOT_NAME });
      }).then(() => {
        setTimeout(() => {
          this.setInputFocusAndScrollHeight();
        }, 300);
      });
      return true;
    } else {
      // to indicate go back to general intent flow
      return false;
    }
  }

  onMouseDown(e: any): void {
    // The mouse button is clicked, which means the start of drawing the signature
    this.isDrawing = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(e: any): void {
    // The mouse button is released, so this means the end of drawing the signature
    this.isDrawing = false;
  }

  onMouseMove(e: any): void {
    // if we're not drawing we need to ignore the events
    if (this.isDrawing) {
      const coords = this.relativeCoords(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
  }

  clearSignature(): void {
    this.context.clearRect(0, 0, this.sigPadElement.width, this.sigPadElement.height);
    this.context.beginPath();
  }

  private relativeCoords(event: any): { x: number, y: number } {
    const bounds = event.target.getBoundingClientRect();
    const cords = {
      clientX: event.clientX || event.changedTouches[0].clientX,
      clientY: event.clientY || event.changedTouches[0].clientY
    };
    const x = cords.clientX - bounds.left;
    const y = cords.clientY - bounds.top;
    return { x, y };
  }

}
