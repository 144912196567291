import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RestProvider } from './providers/rest/rest';
import { SpeechRecognitionService } from './home/speechrecognition.service';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomePageModule } from './home/home.module';

export function setTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [
    AppComponent,
  ],
  imports: [
    BrowserModule, 
    HttpClientModule, 
    IonicModule.forRoot(), 
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (setTranslateLoader),
          deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HomePageModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    RestProvider,
    SpeechRecognitionService
  ],  
  bootstrap: [AppComponent],
  exports: [ TranslateModule]
})
export class AppModule {}
