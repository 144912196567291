import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ENV } from '../../../environments/environment';

/*
  Generated class for the RestProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class RestProvider {

  botApiUrl = ENV.API_URL;
  readonly APP_NAME = 'BI';

  constructor(public http: HttpClient, public translate: TranslateService) {
  }

  askRobot(data, accessToken, selectedLang) {

    console.log("def-lang=" + (this.translate.currentLang == undefined ? this.translate.getDefaultLang() : this.translate.currentLang));
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json').set('Authorization', accessToken)
      .set('x-cera-app', this.APP_NAME)
      .set('Content-Type', 'application/json').set('Accept-Language', selectedLang);

    return new Promise((resolve, reject) => {
      this.http.post(this.botApiUrl + '/messages',
        JSON.stringify(data),
        {
          headers
        }).subscribe(res => {
          resolve(res);
        }, (err) => {
          if(err.status===500){
            this.translate.get('SERVER_ERROR_500').subscribe(translated => { err.message  = translated; });
          }
          if(err.status===504){
            err.message = "Internal server error occurred, please try again later.";
          }
          if(err.status === 0){
            err.message = "Our services are temporarily unavailable. Please try again later.";
          }
          reject(err);
        });
    });
  }

  askSuggestion(data, accessToken, selectedLang) {

    console.log("def-lang=" + this.translate.getDefaultLang());
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json').set('Authorization', accessToken)
      .set('x-cera-app', this.APP_NAME)
      .set('Content-Type', 'application/json').set('Accept-Language', selectedLang);

    return new Promise((resolve, reject) => {
      this.http.post(this.botApiUrl + '/suggestions',
        JSON.stringify(data),
        {
          headers
        }).subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  preLogin(data, selectedLang) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-cera-app', this.APP_NAME)
      .set('Accept-Language', selectedLang);

    return new Promise((resolve, reject) => {
      this.http.post(ENV.AUTH_API_URL + '/chatauth/pre',
        JSON.stringify(data),
        {
          headers
        }).subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  login(data, selectedLang) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-cera-app', this.APP_NAME)
      .set('Accept-Language', selectedLang);

    return new Promise((resolve, reject) => {
      this.http.post(ENV.AUTH_API_URL + '/chatauth/login',
        JSON.stringify(data),
        {
          headers
        }).subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getAvatarImage(accessToken) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json').set('Authorization', accessToken)
      .set('x-cera-app', this.APP_NAME)

    return new Promise((resolve, reject) => {
      this.http.get(this.botApiUrl + '/messages/avatar',
        {
          headers
        }).subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getExpiry(accessToken) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json').set('Authorization', accessToken)
      .set('x-cera-app', this.APP_NAME)

    return new Promise((resolve, reject) => {
      this.http.get(this.botApiUrl + '/messages/expiry',
        {
          headers
        }).subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }
}
